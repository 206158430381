import React, { Component } from 'react';
import './spinner.scss';

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class extends Component {
    render() {
        return (
            <div className="spinner">
                <div>
                    <FontAwesomeIcon icon={faSpinner} />
                </div>
            </div>
        );
    }
}