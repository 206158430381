import React, { useState, useEffect } from 'react';
import './menu.scss';

import { Link, NavLink, useParams, useRouteMatch, useLocation } from "react-router-dom";
import styled from 'styled-components'

import { ReactComponent as ExnextLogo } from '../image/exnext.svg';

import EventsManager from '../events';
import projectsList from './projectsList';
const step = parseFloat((360 / projectsList.length).toFixed(2));

const LiEx = styled.li`
    --angle: ${props => props["data-rotatex"]}deg !important;
    --start-angle: ${props => props["start-angle"]}deg !important;
    --calculated: ${props => props.className ? 0 : (props["data-rotatex"] + props["start-angle"])}deg !important;
    --calculated-reverse: ${props => props.className ? 0 : (-1 * (props["data-rotatex"] + props["start-angle"]))}deg !important;
`;

const DivEx = styled.div`
    --background: ${props => props.color} !important;
`;

// const LinkEx = styled(Link)`
//     --start-color: ${props => props["start-color"]} !important;
// `;

export default function (props) {
    const ref = React.createRef();
    const color = '#4527A0';

    const [stepAngle, setStepAngle] = useState(step);//0
    const [startAngle, setStartAngle] = useState(0);
    const [contentLoadedClass, setContentLoadedClass] = useState('');
    const [off, setOff] = useState('off');

    let projectsRefs = projectsList.reduce((acc, project) => {
        acc[project.title] = React.createRef();
        return acc;
    }, {});

    EventsManager.on('set-angle', (data) => {
        let element = projectsRefs[data.params.name] && projectsRefs[data.params.name].current;

        if (element) {
            let { angle } = element.dataset;
            setStartAngle(90 - angle);
        }
    });

    EventsManager.on('set-content', () => {
        setContentLoadedClass('content-loaded');
    });

    EventsManager.on('clear-content', () => {
        setContentLoadedClass('');
    });

    useEffect(() => {
        setTimeout(() => {
            setOff('');
        }, 200);
    });

    return (
        <div ref={ref} className={`menu-ex ex-prop ${contentLoadedClass} ${!off && 'ready'}`}>
            <Link className="about" to="/about"><ExnextLogo /></Link>

            <ul className="round-list">
                {
                    projectsList.map((project, index) => {
                        return (
                            <LiEx key={index}
                                data-rotatex={stepAngle * index}
                                start-angle={startAngle}
                                className={off}>

                                <DivEx color={props.color || color}>
                                    <NavLink to={'/projects/' + project.title}
                                        ref={projectsRefs[project.title]}
                                        data-angle={stepAngle * index}>
                                        <span>{project.title}</span>
                                    </NavLink>
                                </DivEx>
                            </LiEx>
                        )
                    })
                }
            </ul>
        </div>
    );
}