import React, { useEffect } from 'react';
import './about.scss';

import { Link } from "react-router-dom";

import Container from './container';
import Carousel from 'react-bootstrap/Carousel';

import EventsManager from '../events';
import projectsList from './projectsList';
const carouselItem = projectsList.filter(item => item.image);

export default function () {
    useEffect(() => {
        EventsManager.emit('set-content');
    });

    return (
        <Container title="exnext">
            <div className="home">
                <Carousel>
                    {
                        carouselItem.map((item, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img className="d-block w-100"
                                        src={item.image}
                                        alt={item.title} />

                                    <Carousel.Caption>
                                        <h3><Link to={`/projects/${item.title}`}>{item.title}</Link></h3>
                                        <p>{item.short}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            );
                        })
                    }
                </Carousel>

                <div className="description">
                    <h4>Start-up start-up’ów.</h4>
                    <p>Wszystkie projekty są wynikiem własnych potrzeb. Czy dzięki temu efekt będzie lepszy? Czas pokaże.</p>
                    <p>Wersja robocza strony, ciągle nad nią pracujemy. Nowe zabawki, nowe pomysły. Dużo się jeszcze na niej zmieni.</p> 
                </div>
            </div>
        </Container>
    );

}