const events = [];

class ExNextEventsManager {
    emit(name, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let results = []; 
                let toExecute = events.filter(event => event.name === name);

                for(let event of toExecute) {
                    if (event.once) {
                        let index = events.indexOf(event);
                        events.splice(index, 1);
                    }
        
                    if (event.callback) {
                        let result = event.callback(data);
        
                        if (result instanceof Promise) {
                            result = await result;
                        }
        
                        results.push(result);
                    }
                }

                resolve(results);
            } catch(error) {
                reject(error);
            }
        });
    }

    once(name, callback) {
        events.push({ name, callback, once: true });
    }

    on(name, callback) {
        events.push({ name, callback });
    }

    delete(name, callback) {
        let toDelete = events.filter(event => event.name === name);

        if (callback) {
            toDelete = toDelete.filter(event => event.callback === callback);
        }

        toDelete.forEach((event) => {
            let index = events.indexOf(event);
            events.splice(index, 1);
        });
    }
}

const EventsManager = new ExNextEventsManager();

export default EventsManager;