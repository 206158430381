import React from 'react';
import './footer.scss';

import { Link } from 'react-router-dom';

import { ReactComponent as ExnextLogo } from '../image/exnext.svg';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function () {
    const year = new Date().getFullYear();

    return (
        <footer className="footer-ex box-shadowing">
            <span>© Copyright <ExnextLogo className="exnext-logo" /> <a href="//www.exnext.pl">exnext.pl</a> {year} </span>
            <Link to="/contact" className="link"><FontAwesomeIcon icon={faEnvelope} /></Link>
            <a href="//www.facebook.pl/exnextpl" target="_blank" rel="noopener noreferrer" className="link"><FontAwesomeIcon icon={faFacebook} /></a>
        </footer>
    );
}