import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import About from './about';
import Projects from './projects';
import Contact from './contact';

export default function (props) {
    return (
        <Switch>
            <Route path="/about">
                <About />
            </Route>
            <Route exact path="/projects">
                <Projects />
            </Route>
            <Route path="/projects/:name">
                <Projects />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route>
                <Redirect to="/" />
            </Route>
        </Switch>
    );
}