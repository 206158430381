import React, { Component } from 'react';
import './language.scss';

import { ReactComponent as Pol } from '../image/lang/pol.svg';
import { ReactComponent as Eng } from '../image/lang/eng.svg';

const supportLang = ['pl', 'en'];

export default class extends Component {
    constructor(props) {
        super(props);

        let currentLang = navigator.language.slice(0, 2);

        if (supportLang.indexOf(currentLang) === -1) {
            currentLang = 'en';
        }

        document.documentElement.lang = currentLang;
        this.state = { currentLang };
        this.changeLang = this.changeLang.bind(this);
    }

    changeLang() {
        const currentLang = this.state.currentLang === 'pl' ? 'en' : 'pl';
        document.documentElement.lang = currentLang; 
        this.setState(state => ({ currentLang }));
    }

    lang() {
        return this.state.currentLang === 'en' ? <Pol /> : <Eng />
    }

    render() {
        return (
            <div className="language" onClick={this.changeLang}>
                {this.lang()}
            </div>
        );
    }
}