import stockex from '../image/stockex.jpg';
import lottogra from '../image/lotto.jpg';
import tobi from '../image/tobi.jpg';

import StockEx from './projects/stockex';

const projectsList = [
    {
        title: 'stockex',
        short: 'Zysk z udanej inwestycji',
        image: stockex,
        color: '#3949AB',
        component: StockEx
    },
    {
        title: 'lottogra',
        description: 'Czy w dzisiejszych czasach gry losowe to tylko i wyłącznie prawdopodobieństwo? A może da się znaleźć jakieś zależności? Sprawdź sam...',
        short: 'Droga do pierwszego miliona',
        image: lottogra,
        color: '#43A047',
        ready: 25
    },
    {
        title: 'tobi',
        description: 'Platforma wspomagająca naukę języków obcych poprzez "kontakt" z wirtualnym native speakerem.',
        short: 'Nuka języka bez poczucia wsydu...',
        image: tobi,
        color: '#FB8C00',
        ready: 80
    },
    {
        title: 'piggybank',
        description: 'Prosty serwis wspomagający planowanie budżetu.',
        color: '#8E24AA',
        ready: 50
    },
    {
        title: 'shortlink',
        description: 'Prosty serwis do generowania krótkich linków, przydatny zwłaszcza w sieciach lokalnych.',
        color: '#00ACC1',
        url: '//github.com/exnext/shortlink'
    },
    {
        title: 'colorex',
        description: 'Biblioteka to szybkiego budowania komponentów pozwalających wybrać kolor',
        color: '#546E7A',
        url: '//www.npmjs.com/package/colorex'
    },
    {
        title: 'rotatex',
        description: 'Biblioteka wspomagająca w szybki sposób buodwanie okrągłych menu. Użyta na naszej stronie a w najbliższym czasie udostępniona będzie na GitHub',
        color: '#546E7A',
        ready: 90
    },
    {
        title: 'raudion',
        description: 'Kilka skryptów pozwalających wykorzystanie ulubionych radiostacji internetowych na kilku urządzeniach',
        color: '#546E7A'
    }
];

// projectsList.sort((a, b) => {
//     return a.title.length > b.title.length ? 1 : -1
// });

export default projectsList;