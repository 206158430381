import React, { Component } from 'react';
import './contact.scss';

import axios from 'axios';
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import Container from './container';
import Spinner from './spinner';

import EventsManager from '../events';

export default class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            subject: '',
            message: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.invalidForm = this.invalidForm.bind(this);
    }

    componentDidMount() {
        EventsManager.emit('set-content');
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        let { name, email, subject, message, recaptchaToken } = this.state;

        this.setState({
            sending: true
        });

        axios.post(`${window.location.origin}/send-contact`, { name, email, subject, message, recaptchaToken })
            .then((result) => {
                this.setState({
                    sent: true
                });
            })
            .catch((error) => {
                this.setState({
                    error
                });
            });

        event.preventDefault();
    }

    onChange(recaptchaToken) {
        this.setState({
            recaptchaToken
        });
    }

    invalidForm() {
        let { name, email, subject, message, recaptchaToken } = this.state;
        return !name || !email || !subject || !message || !recaptchaToken;
    }

    render() {
        if (this.state.sent) {
            EventsManager.emit('clear-content');
            
            return <Redirect to='/' />
        }

        return (
            <Container title="kontakt">
                <div className="contact">
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <label> Imię i nazwisko</label>
                            <div>
                                <input type="text" name="name" value={this.state.name} onChange={this.handleChange} className="form-control" />
                            </div>
                        </div>

                        <div>
                            <label> Adres email</label>
                            <div>
                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" />
                            </div>
                        </div>

                        <div>
                            <label> Temat</label>
                            <div>
                                <input type="text" name="subject" value={this.state.subject} onChange={this.handleChange} className="form-control" />
                            </div>
                        </div>

                        <div>
                            <label> Treść wiadomości</label>
                            <div>
                                <textarea name="message" rows="5" value={this.state.message} onChange={this.handleChange} className="form-control"></textarea>
                            </div>
                        </div>

                        {
                            this.state.error && <div class="alert alert-danger" role="alert">
                                {this.state.error.response.data}
                            </div>
                        }

                        <div className="recaptcha">
                            <ReCAPTCHA sitekey="6LfIM-kUAAAAAHLXUoY1Jo63x-yEN2_yTfINgRrf" onChange={this.onChange} size="compact"/>
                        </div>

                        <div>
                            <input type="submit" value="Wyślij" className="btn btn-dark" disabled={this.invalidForm() || this.state.sending}/>
                        </div>
                    </form>
                </div>
                {/* <Spinner /> */}
            </Container>
        );
    }
}