import React, { useState } from 'react';
import './cookieLaw.scss';

import { useCookies } from 'react-cookie';

import Watermark from './watermark';

import { faCookieBite, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function () {
    const name = '_cle';
    const [cookies, setCookie] = useCookies(['cookie-name']);
    const [cle, setCle] = useState(!!cookies[name]);

    function accept() {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        let options = {
            path: '/',
            expires: date
        };

        setCookie(name, 'accepted', options);
        setCle(true);
    }

    return !cle && (
        <div className="cookie-law-ex">
            <div className="message">
                <div className="cookie">
                    <FontAwesomeIcon icon={faCookieBite} />
                </div>

                <div>
                    Niniejsza strona internetowa używa plików cookies oraz local storage w celach reklamowych, funkcjonalnych oraz dopasowania wyglądu
                    i treści strony do indywidualnych potrzeb użytkownika. Pozostawiając w ustawieniach przeglądarki włączoną obsługę plików cookies
                    wyrażasz zgodę na ich użycie, w przeciwnym wypadku zmień ustawienia swojej przeglądarki.
                </div>

                <div className="action">
                    <button className="btn btn-link" onClick={accept}><FontAwesomeIcon icon={faTimes} /></button>
                </div>
            </div>

            <Watermark />
        </div>
    );
}