import React, { Component } from 'react';
import './watermark.scss';

import { ReactComponent as ExnextLogo } from '../image/exnext.svg';

export default class extends Component {
    render() {
        return (
            <div className="watermark">
                <ExnextLogo />
            </div>
        );
    }
}