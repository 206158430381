import React, { Component } from 'react';
import './container.scss';

import { Link } from "react-router-dom";

import EventsManager from '../events';

import { ReactComponent as ExnextLogo } from '../image/exnext.svg';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Watermark from './watermark';

export default function (props) {
    function click(event) {
        EventsManager.emit('clear-content');
    }

    return (
        <div className="container-ex">
            <div className="top">
                <div className="action">
                    <Link to="/" onClick={click}><FontAwesomeIcon icon={faAngleDoubleRight} /></Link>
                </div>

                <div className="header"><h2>{props.title}</h2></div>
            </div>

            <div className="children">
                {props.children}
            </div>

            <Watermark />
        </div>
    );
}