import React, { useEffect } from 'react';
import './projects.scss';

import { Link, Redirect, useParams } from "react-router-dom";
import Container from './container';

import EventsManager from '../events';
import projectsList from './projectsList';

export default function (props) {
    let { name } = useParams();
    let project = projectsList.find((item) => item.title === name);

    let data = { params: useParams() };
    useEffect(() => {
        EventsManager.emit('set-angle', data);
        EventsManager.emit('set-content');
    });

    if (!project) {
        return (
            <Redirect to={`/projects/${projectsList[0].title}`} />
        );
    }

    return (
        <Container title={project.title}>
            <div className="project-ex">
                {
                    !project.component && project.image && <img className="d-block w-100"
                        src={project.image}
                        alt={project.title} />
                }

                {
                    project.component
                        ? (
                            <project.component></project.component>
                        )
                        : (
                            <div className="description">
                                <p>
                                    {project.description}
                                </p>
                                {
                                    project.url && (
                                        <div>
                                            Zobacz: <Link to={project.url}>{project.title}</Link>
                                        </div>
                                    )
                                }
                            </div>
                        )
                }
            </div>
        </Container>
    );
}

