import React from 'react';
import './exnext.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import SvgFilter from './app/svgFilter';
import ExnextRouter from './app/router';
import CookieLaw from './app/cookieLaw';
import Menu from './app/menu';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/rotatex.scss';
import './scss/proportional.scss';

import Language from './app/language';
import FooterEx from './app/footer';

ReactGA.initialize('UA-114386685-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function () {
    return (
        <Router>
            <div className="exnext">
                <div className="working-version">
                    Wersja robocza strony, ciągle nad nią pracujemy.
                </div>

                <CookieLaw />
                {/* <Language /> */}
                <Menu />
                <div className="content">
                    <ExnextRouter />
                </div>

                <FooterEx />
                <SvgFilter />
            </div>
        </Router>
    );

}